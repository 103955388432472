<template>
  <div class="container mt-2 mb-4">
    <app-title title="My password" :underline="false" />
    <div class="row mt-4 mb-4 password-row">
      <div class="col-12 col-lg-6">
        <app-form
          :form="form"
          :model="model"
          :rules="rules"
          button="Reset"
          @submitted="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { form, model, rules } from '@/utils/config/password';
import Database from '@/utils/services/Database';

export default {
  name: 'UserPassword',
  data() {
    return {
      form,
      model,
      rules,
    };
  },
  methods: {
    async handleSubmit(data) {
      const status = await Database.updatePassword(
        data.old_password,
        data.new_password
      );

      if (status === 200) return this.showSuccess();

      return this.$message({
          type: 'error',
          message: 'Something went wrong. Please try again later.',
          offset: 200,
        });
    },
    async showSuccess() {
      await this.$message({
        type: 'success',
        message: 'Password successfully updated.',
        offset: 200,
      });

      this.$router.push('/u/dashboard');
    },
  },
};
</script>
